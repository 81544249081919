import "./App.css";

import { useEffect, useState } from "react";
import CardMedia from '@mui/material/CardMedia';

import { Clock, Player } from "./components";

import mgraskerSpaceDrop from "./MgraskerSpaceDrop.jpg"

let songData = require("./PreMaster.json");

function getWindowDimensions() {
  var { innerWidth: width, innerHeight: height } = window;
  height = height - 75;
  return {
    width,
    height
  };
}

// hook for getting window dimensions
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


function App() {

  const [handRotation, setHandRotation] = useState(-90);
  const [currentSong, setCurrentSong] = useState(songData[0].title);
  const [currentAudio, setCurrentAudio] = useState();
  const [transportPlaying, setTransportPlaying] = useState();

  const { height, width } = useWindowDimensions();

  return (
    <div className="App">
      <CardMedia 
        image={mgraskerSpaceDrop}
        sx={{
          height: `${height}px`,
        }}
      >
        <Clock 
          handRotation={handRotation} 
          setHandRotation={setHandRotation} 
          songData={songData} 
          currentSong={currentSong} 
          setCurrentSong={setCurrentSong}
          currentAudio={currentAudio}
          setCurrentAudio={setCurrentAudio}
          transportPlaying={transportPlaying}
          maxHeight={Math.min(height, width)}
        />
        <Player 
          currentSong={currentSong}
          currentAudio={currentAudio}
          songData={songData}
          transportPlaying={transportPlaying}
          setTransportPlaying={setTransportPlaying}
          screenHeight={height}
          screenWidth={height}
        />
      </CardMedia>
    </div>
  );
}

export default App;
