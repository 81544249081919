import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocalDiningIcon from '@mui/icons-material/LocalDining';

import AudioSpectrum from '../components/AudioSpectrum';


export default function Player(props) {

  const togglePlaying = () => {
    if (!props.transportPlaying) {
      props.setTransportPlaying(true);
    }
    // there is no pausing
  }

  function indexOfCurrent() {
    return props.songData.map(function(e) { return e.title; }).indexOf(props.currentSong) + 1
  }

  return (
    <div style={{ 
      position: "absolute", 
      bottom: 0, 
      left: 0, 
      width: "100%",
      height: "75px",
      paddingBottom: 0,
      backgroundColor: "black",
      color: "white",
    }}>
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        {props.currentSong}
        <Fab onClick={togglePlaying} color="white" aria-label="add">
          {props.transportPlaying ? <BlurOnIcon /> : <PlayCircleIcon />}
        </Fab>
      </Box>
    </div>
  );

}
