import React, { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import LinkIcon from '@mui/icons-material/Link';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';


import hourHand from "../hourhand.png"
import minuteHand from "../minutehand.png"
import rotatingSphere from "../rotatingsphere.gif"


function SongTick(props) {

  // state
  const [ style, setStyle ] = useState();
  const [ audio, setAudio ] = useState();
  const [ playing, setPlaying ] = useState();
  const [ hover, setHover ] = useState(false);


  // rotation and transformation of circle
  const tickSize = (360 / props.totalCount);
  const rotation = (tickSize * props.index) - (90 + tickSize);
  const radius = (props.maxHeight) / 6;
  const transform = `rotate(${rotation}deg) translate(${radius}px)`;
  const transformOrigin = `top left`;

  // component logic
  function current() {
    return (props.currentSong === props.title)
  }

  function indexOfCurrent() {
    return props.songData.map(function(e) { return e.title; }).indexOf(props.currentSong) + 1
  }


  function setAsCurrent() {
    props.setCurrentSong(props.title);
    props.setHandRotation(rotation);
  }


  function setNextAsCurrent() {
    console.log(`setting next song ${props.nextSong.title} as current`);
    props.setCurrentSong(props.nextSong.title);
    props.setHandRotation(rotation+tickSize);
  }


  function reconcilePlayingState() {
    if (audio) {
      if (indexOfCurrent() === props.index) {
          audio.preload = "auto";
      }
      if (indexOfCurrent() + 1 === props.index) {
          setTimeout(function() {
              audio.preload = "auto";
          }, 5000);
      }
      if (props.transportPlaying && current()) {
        console.log(`Playing ${props.title} readyState: ${audio.readyState}, at: ${audio.currentTime}/${audio.duration}`);
        let promise = audio.play();
        if (promise !== undefined) {
        promise.catch(error => {
            console.log("playing audio raised an error")
            props.setSupportedDevice(false)
            // Auto-play was prevented
            // Show a UI element to let the user manually start playback
        }).then(() => {
            // Auto-play started
        });
    }

        props.setCurrentAudio(audio);
        audio.addEventListener('timeupdate', function () { 
          const percentage = audio.currentTime/audio.duration;
          //console.log(`time update hit, set current percentage: ${percentage}`);
          props.setCurrentSongPercentage(percentage)
        });
        setPlaying(true);
      } else if (playing) {
        audio.pause();
        setPlaying(false);
      }
    }
  }

  // setup each audio file once
  useEffect(() => {
    const fetchPresignedSongUrl = async () => {
        //console.log(`setting audio for ${props.title}, next title: ${props.nextSong}`);
        var newAudio = new Audio(`https://mgrasker.com/media/music/${props.title}`);
        newAudio.onended = function() { setNextAsCurrent() };
        newAudio.preload = "none";
        newAudio.crossOrigin = "anonymous";
        newAudio.id = props.title;
        console.log(`new audio source; ${newAudio.src}, preload: ${newAudio.preload}`);
        setAudio(newAudio);
    }
    fetchPresignedSongUrl()
  }, [])

  // update playing state of all SongTicks when song changes or transport toggles
  useEffect(() => {
    setStyle({
      position: "absolute",
      listStyle: "none",
      transform: transform,
      cursor: "pointer",
      transformOrigin: transformOrigin,
      color: current() && props.transportPlaying ? "green" : "white",
    }); 
    reconcilePlayingState();
  }, [props.currentSong, props.transportPlaying])

  useEffect(() => {
    setStyle({
      position: "absolute",
      listStyle: "none",
      transform: transform,
      cursor: "pointer",
      transformOrigin: transformOrigin,
      color: current() && props.transportPlaying ? "green" : "white",
      fontSize: hover ? "110%" : "100%",
      textDecoration: hover ? "underline overline" : "none",
    }); 
  }, [props.maxHeight, hover])


  return (<li style={style} onMouseEnter={() => {setHover(true);}} onMouseLeave={() => {setHover(false);}} key={props.index+1}><a onClick={setAsCurrent}>{props.index} - {props.title.replace(".mp3", "")}</a></li>)
}

export default function Clock(props) {


  const [ itemList, setItemList] = useState();
  const [ currentSongPercentage, setCurrentSongPercentage ] = useState(0);
  const [ modalOpen, setModalOpen ] = useState(false);
  const [ infoModalOpen, setInfoModalOpen ] = useState(false);
  const [ supportedDevice, setSupportedDevice ] = useState(true);
  const [ showKujakuCutLink, setShowKujakuCutLink ] = useState(false);

  useEffect(() => {
    setItemList(props.songData.map((song, index) => {
      return <SongTick 
        handRotation={props.handRotation} 
        setHandRotation={props.setHandRotation}
        totalCount={props.songData.length} 
        songData={props.songData}
        index={index+1} 
        title={song.title} 
        nextSong={props.songData[index+1] ? props.songData[index+1] : props.songData[0]}
        currentSong={props.currentSong}
        setCurrentSong={props.setCurrentSong}
        currentAudio={props.currentAudio}
        setCurrentAudio={props.setCurrentAudio}
        setCurrentSongPercentage={setCurrentSongPercentage}
        transportPlaying={props.transportPlaying}
        maxHeight={props.maxHeight}
        setSupportedDevice={setSupportedDevice}
      />
    }));
  }, [props.songData, props.currentSong, props.transportPlaying, props.maxHeight])

  const handStyle = {
    position: "absolute",
    listStyle: "none",
    transform: `rotate(${props.handRotation}deg) translate(${props.maxHeight/6.5}px,-25px)`,
    transformOrigin: "top left"
  }
  const percentageHandStyle = {
    position: "absolute",
    listStyle: "none",
    transform: `rotate(${(currentSongPercentage*360) + props.handRotation}deg) translate(${(props.maxHeight/6.5) - 80}px, -25px)`,
    transformOrigin: "top left"
  }
  const fastHandStyle = {
    position: "absolute",
    listStyle: "none",
    transform: `rotate(${(currentSongPercentage*1080) + props.handRotation}deg) translate(${(props.maxHeight/4) + 140}px, -10px)`,
    transformOrigin: "top left"
  }

  function handleModalClose() {
    setModalOpen(false)
  }

  function handleModalOpen() {
    setModalOpen(true)
  }

  function handleInfoModalClose() {
    setInfoModalOpen(false)
  }

  function handleInfoModalOpen() {
    setInfoModalOpen(true)
  }

  useEffect(() => {
    if (!supportedDevice) {
        handleModalOpen()
    }
    console.log(`supported device: ${supportedDevice}`);
  }, [supportedDevice])

  useEffect(() => {
    if (props.currentSong.includes("Ludestorm")) {
      setShowKujakuCutLink(true)
    } else {
      setShowKujakuCutLink(false)
    }
  }, [props.currentSong])

  return (
    <div>
      { showKujakuCutLink ?
        <div style={{position: "absolute", left: "50%", backgroundColor: "white"}}><a target="_blank" href="https://youtu.be/v8V5T163H6o">You've unlocked the Kujaku Cut!</a></div>
        : <div></div>
      }
      <Button sx={{backgroundColor: '#00a152', top: '10px', right: '10px', float: 'right'}} variant="contained" onClick={handleModalOpen}><LinkIcon/></Button>
      <Button sx={{backgroundColor: '#00a152', top: '10px', left: '10px', float: 'left'}} variant="contained" onClick={handleInfoModalOpen}><HelpCenterIcon/></Button>
      <Modal
        keepMounted
        open={infoModalOpen}
        onClose={handleInfoModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          maxWidth: '90%',
          maxHeight: '70%',
          overflowY: 'scroll',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h4>Under The Righteous Sky</h4>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            M’Graskorp Unlimited Enterprises and Subordinates of M'Graskgroup Industries present: A rock opera in 69 parts by the band M’Grasker
            <br/>
            <br/>
            Written, produced, and performed by M’Grasker.
            <br/>
            <br/>
            The following meat husks appeared in a “guest” capacity, M’Grasker feels the emotion of gratitude towards:
            <br/>
            <br/>
            Mackenzie Jordan, Andy Blatherwick, Steve Lavery, Ian McKeown, Alyson McNamara, Meg Contini, Paul Reginato
            <br/>
            <br/>
            And introducing Brondo, as himself.
            <br/>
            <br/>
            Recorded on planet earth, we can't be more specific.
            <br/>
            <br/>
            Thanks to: Kujaku, David Lunch, Sam Wong, Spice Diver, Glanduril, Shai Hulud, Blowey Chilgar, Frank Herbert, Kyle MacLachlan, Jom Guitar, Muad'Dib and his best friend Stilgar. 
            <br/>
            <br/>
            <h2>IGNORE THE BAND MGRASKER</h2>
          </Typography>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          maxWidth: '90%',
          maxHeight: '70%',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          overflowY: 'scroll',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Clockless Release Formats
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Sadly the M'Grasker Clock of Space and Tim™ does not work on more "security-conscious" devices. In case of emergency, Under the Righteous Sky can be heard on these other inferior platforms for music distribution:
            <br/>
            <br/>
            <a href="https://mgraskertheband.bandcamp.com/album/under-the-righteous-sky" target="_blank">Bandcamp</a>
            <br/>
            <a href="https://open.spotify.com/album/0Ygj042QLZF8oBSbU0OtGS?si=CV5Dr3-OTiWoPpg0XM0t9Q" target="_blank">Spotify</a>
            <br/>
            <a href="https://music.apple.com/ca/album/1619312579?app=music&at=1l3vpUI&lId=25543226&cId=none&sr=1&src=Linkfire&itscg=30440&itsct=catchall_p1&ct=LFV_999d7975183017db7d2869fbedf4a001&ls=1" target="_blank">Apple Music</a>
            <br/>
            <a href="http://listen.tidalhifi.com/album/225118167" target="_blank">Tidal (Ian's Favourite)</a>
            <br/>
            <a href="https://cleo-ayla-academics.lnk.to/02aNMI" target="_blank">Other Streaming Services</a>
            <br/>
            <br/>
            If visual consumption is possible, we highly recommend listening here: <a href="https://www.youtube.com/watch?v=pmqA3cE1Q8g" target="_blank">Dune: Under The Righteous Sky (Alternative Edition Redux)</a>
            <br/>
          </Typography>
        </Box>
      </Modal>
      <div style={{ 
        position: "absolute", 
        top: "50%", 
        right: "50%", 
        transform: `translate(100%, -100%)`,
        width: "30em"
      }}>
      <ul>
        <li key={"songHand"} style={handStyle}>
          <img style={{
            height: "70px", width: "auto", transform: "rotate(90deg)" 
          }} src={hourHand}/>
        </li>
        <li key={"percentageHand"} style={percentageHandStyle}>
          <img style={{
            height: "70px", width: "auto", transform: "rotate(90deg)" 
          }} src={minuteHand}/>
        </li>
        <li key={"fastHand"} style={fastHandStyle}>
          <img style={{
            height: "40px", width: "auto", transform: "rotate(90deg)" 
          }} src={rotatingSphere}/>
        </li>
        {itemList}
      </ul>
      </div>
    </div>
  )

}

